import React from 'react';
import classnames from 'classnames';

import styles from './badge.module.css';

interface IInspireBadgeProps {
    className?: string;
    value: string;
    dataTestId?: string;
    theme?: 'dark' | 'light' | 'deepdark' | 'primary' | 'secondary';
    textColor?: string;
    backgroundColor?: string;
    fontSizeStyle?: string;
}

const InspireBadge = ({
    className,
    value,
    dataTestId,
    textColor,
    backgroundColor,
    theme = 'dark',
    fontSizeStyle,
}: IInspireBadgeProps): JSX.Element => {
    return (
        <div
            className={classnames(styles.badge, styles[className], {
                [styles.dark]: theme === 'dark',
                [styles.light]: theme === 'light',
                [styles.deepdark]: theme === 'deepdark',
                [styles.primary]: theme === 'primary',
                [styles.secondary]: theme === 'secondary',
            })}
            data-testid={dataTestId}
            id={dataTestId}
            style={{
                ...(textColor && { color: textColor }),
                ...(backgroundColor && { backgroundColor }),
                ...(fontSizeStyle && { fontSize: `${fontSizeStyle}px` }),
            }}
        >
            {value}
        </div>
    );
};

export default InspireBadge;
