import React, { useMemo, FC } from 'react';
import Link from 'next/link';

import { IMenuCategory, IProduct, ITapListMenuCategory } from '../../../@generated/@types/contentful';
import ContentfulImage from '../ContentfulImage';

import styles from './menuCategoryCard.module.css';
import MenuCategoryCardTextContent from './MenuCategoryCardTextContent/MenuCategoryCardTextContent';
import isTapListMenuCategory from '../../../common/helpers/isTapListMenuCategory';
import classNames from 'classnames';
import { spaces2underscores } from '../../../lib/gtm';
import ContentfulBadge from '../../molecules/contentfulBadge';
import { ItemType } from '../../../common/constants/badge';
import { CONSTANTS } from '../../../constants/en/commons';
import { useBadge } from '../../../common/hooks/useBadge';

const isProductVisible = (product: IProduct) => product.fields?.isVisible;

export interface IMenuCategoryCardProps {
    isLocationOrderAvailable: boolean;
    card: IMenuCategory | ITapListMenuCategory;
    className?: string;
    parentCategoryUrl?: string;
}

const MenuCategoryCard: FC<IMenuCategoryCardProps> = ({
    isLocationOrderAvailable,
    card,
    className,
    parentCategoryUrl,
}) => {
    const {
        image,
        categoryName,
        categoryDescription,
        link: {
            fields: { nameInUrl },
        },
    } = card.fields;
    const { VIEW_ALL, ORDER } = CONSTANTS;
    const linkText = isLocationOrderAvailable ? ORDER : VIEW_ALL;
    const { getBadge } = useBadge();

    const productCount = useMemo(() => {
        if (!isTapListMenuCategory(card)) {
            const { products, subcategories } = card.fields;
            const productsCount = products?.filter(isProductVisible)?.length || 0;
            const productsInSubcategoriesCount =
                subcategories?.reduce(
                    (acc, subcategory) => (subcategory?.fields?.products?.filter(isProductVisible)?.length || 0) + acc,
                    0
                ) || 0;

            return productsCount + productsInSubcategoriesCount;
        }
    }, [card]);

    const gtmId = spaces2underscores(categoryName);

    const menuCategoryLinkHref = parentCategoryUrl
        ? '/menu/[menuCategoryUrl]/[...nestedCategoryOrPDPPageUrl]'
        : '/menu/[menuCategoryUrl]';
    const menuCategoryLinkAs = parentCategoryUrl ? `/menu/${parentCategoryUrl}/${nameInUrl}` : `/menu/${nameInUrl}`;
    const badgeDetails = getBadge(ItemType.CATEGORIES, card.fields['categoryId'] || '');

    const linkIdDescription = `instructions-${nameInUrl}`.replace(/ /g, '-');
    return (
        <Link href={menuCategoryLinkHref} as={menuCategoryLinkAs}>
            <a
                data-gtm-id={gtmId}
                className={classNames(styles.menuCategoryCard, className, {
                    [styles.badgeMenuCategoryCard]: badgeDetails?.shouldDisplayBadge,
                })}
                aria-labelledby={linkIdDescription}
            >
                <ContentfulBadge className={styles.contentfulBadge} content={badgeDetails} />
                <div data-gtm-id={gtmId} className={styles.imageSection}>
                    <ContentfulImage gtmId={gtmId} asset={image} className={styles.image} maxWidth={187} />
                </div>
                <div data-gtm-id={gtmId} className={styles.contentSection} id={linkIdDescription}>
                    <h3
                        data-gtm-id={gtmId}
                        className={classNames('t-header-h2', 'truncate-at-3', styles.title)}
                        aria-label={`Menu Category ${categoryName}`}
                    >
                        {categoryName}
                    </h3>
                    <MenuCategoryCardTextContent
                        gtmId={gtmId}
                        productCount={productCount}
                        linkText={linkText}
                        description={categoryDescription}
                    />
                </div>
            </a>
        </Link>
    );
};

export default MenuCategoryCard;
