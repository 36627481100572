import React from 'react';
import styles from './menuCategoryCardTextContext.module.css';
import { IMenuCategoryCardTextContent } from './types';

const MenuCategoryCardTextContent = ({ gtmId, productCount, linkText }: IMenuCategoryCardTextContent): JSX.Element => {
    return (
        <>
            <span data-gtm-id={gtmId} className={styles.counter}>{`${productCount} items`}</span>
            <span data-gtm-id={gtmId} className={styles.link}>
                {linkText}
            </span>
        </>
    );
};

export default MenuCategoryCardTextContent;
