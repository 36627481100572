import React from 'react';

import { IMenuCategorySection } from '../../../@generated/@types/contentful';
import { useLocationOrderAheadAvailability } from '../../../common/hooks/useLocationOrderAheadAvailability';
import MenuCategoryCard from '../../atoms/menuCategoryCard';
import { ISectionComponentProps } from '..';
import styles from './menuCategory.module.css';
import { useDomainMenu } from '../../../redux/hooks';

interface IMenuCategorySectionProps extends ISectionComponentProps {
    entry: IMenuCategorySection;
}

function MenuCategoryUI(props: IMenuCategorySectionProps): JSX.Element {
    const {
        actions: { getAvailableCategories },
    } = useDomainMenu();

    const { backgroundPosition, backgroundBottomText, backgroundBottomTextSecondPart } = props.entry.fields;
    const { categories: cmsCategories } = props.entry.fields;

    const availableCategories = getAvailableCategories(cmsCategories);

    const threeColumn = availableCategories.length % 3 === 0 ? styles.hideWhenWide : '';
    const needsFiller = availableCategories.length % 3 === 1 ? styles.showWhenWide : styles.hidden;
    const { isLocationOrderAheadAvailable } = useLocationOrderAheadAvailability();

    return (
        <div className={styles.sectionFlexContainer} data-testid="category-menu-selector">
            {backgroundPosition === 'front' && (
                <div className={`${styles.menuCategoryCard} ${threeColumn} ${styles.backgroundBottomTextSection}`}>
                    {backgroundBottomText ? (
                        <div className={styles.backgroundBottomText}>
                            <div>{backgroundBottomText}</div>
                            <div>{backgroundBottomTextSecondPart}</div>
                        </div>
                    ) : null}
                </div>
            )}
            {availableCategories.map((c, idx) => (
                <MenuCategoryCard
                    isLocationOrderAvailable={isLocationOrderAheadAvailable}
                    card={c}
                    key={`${c.sys.id}-${idx}`}
                    className={styles.card}
                />
            ))}

            {availableCategories.length % 3 !== 2 && (
                <section className={`${styles.menuCategoryCard} ${needsFiller}`} />
            )}

            {backgroundPosition === 'back' && (
                <div className={`${styles.menuCategoryCard} ${threeColumn} ${styles.backgroundBottomTextSection}`}>
                    {backgroundBottomText ? (
                        <div className={styles.backgroundBottomText}>
                            <div>{backgroundBottomText}</div>
                            <div>{backgroundBottomTextSecondPart}</div>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
}

export default MenuCategoryUI;
