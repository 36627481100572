import React from 'react';
import classnames from 'classnames';
import { Badge } from '../../../common/hooks/useBadge';
import InspireBadge from '../../atoms/Badge/badge';
import styles from './contentfulBadge.module.css';

interface IContentfulBadge {
    content: Badge;
    className?: string;
}

const ContentfulBadge = ({ content, className }: IContentfulBadge) => {
    const { shouldDisplayBadge, backgroundColor, displayText, textColor, itemId } = content || {};

    if (!shouldDisplayBadge) {
        return <></>;
    }

    return (
        <div className={classnames(styles.container, className)}>
            <InspireBadge
                className="contentfulBadge"
                dataTestId={`badges-product-${itemId}`}
                value={displayText}
                textColor={textColor}
                backgroundColor={backgroundColor}
            />
        </div>
    );
};

export default ContentfulBadge;
