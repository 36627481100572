import { useGlobalProps } from '../../redux/hooks';
import { isAfter, isBefore, startOfDay, endOfDay } from '../helpers/dateTime';
import { useConfiguration } from './useConfiguration';
import { ItemType } from '../constants/badge';

export type Badge = {
    shouldDisplayBadge: boolean;
    displayText?: string;
    textColor?: string;
    backgroundColor?: string;
    itemId?: string;
};

interface IUseBadgeHook {
    getBadge: (type: ItemType, itemId: string | undefined) => Badge;
}

export const useBadge = (): IUseBadgeHook => {
    const {
        configuration: { isBadgeEnabled },
    } = useConfiguration();
    const { badgesById, badgesByItemType } = useGlobalProps();

    /**
     * Method to get badge data for the provided itemId
     * @method getBadge
     * @author Yogesh Panghaal <yogesh.p@inspirebrands.com>
     * @param {ItemType} type is provided to distinguish between badges set for
     * menuCategories and products
     * @param {string | undefined} itemId denotes to the categoryId if badge
     * details need to be fetched for a menu category. It denotes to productId if
     * badge details need to be fetched for a product.
     * @returns {Badge} Following badge details will be retuned:
     * {boolean} shouldDisplayBadge - Whether badge needs to be displayed or not
     * {string} displayText - Text that needs to be displayed in the badge
     * {string} textColor - Color hexcode for the text displayed in the badge
     * {string} backgroundColor - Color hexcode for the badge background
     * @example
     * const { productId } = card.fields; // Card type is InspireCmdEntry<IProductFields>
     * const { getBadge } = useBadge();
     * const badge = getBadge(ItemType.PRODUCTS, productId);
     * return badge?.shouldDisplayBadge &&  (
     *    <InspireBadge
     *        className="productBadge"
     *        value={badge.displayText}
     *        textColor={badge.textColor}
     *        backgroundColor={badge.backgroundColor} />
     * );
     */
    const getBadge = (type: ItemType, itemId: string | undefined) => {
        const badgeInfo: Badge = {
            shouldDisplayBadge: false,
            itemId: itemId,
        };
        const badgeItemId = badgesByItemType?.[type]?.[itemId];
        const badge = badgeItemId && badgesById?.[badgeItemId]?.fields;
        if (isBadgeEnabled && badge) {
            const now = new Date();
            const startDate = startOfDay(new Date(badge.startDate));
            const endDate = endOfDay(new Date(badge.endDate));
            if (!(isBefore(now, startDate) || isAfter(now, endDate))) {
                badgeInfo.shouldDisplayBadge = true;
                badgeInfo.displayText = badge.displayText;
                badgeInfo.textColor = `#${badge.textColor?.fields?.hexColor}`;
                badgeInfo.backgroundColor = `#${badge.backgroundColor?.fields?.hexColor}`;
            }
        }

        return badgeInfo;
    };

    return { getBadge };
};
